
export default function OurVision() {

    return (
        <div>
            <div className="our-vision-title" data-aos="flip-down" data-aos-easing="linear" data-aos-duration="600">Inspiring Change through Our Vision</div>
            <div className="container">
                <div className="row rowGap ">
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="our-vision-tab-new" data-aos="zoom-in-right" data-aos-easing="linear" data-aos-duration="600">
                            <div className="vision-number">1</div>
                            <div className="vision-title">Innovation</div>
                            <div className="vision-content">
                            We are committed to continuously pushing the boundaries of mechanical design. Our vision is to pioneer new technologies and approaches that redefine industry standards.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-3 ">
                        <div className="our-vision-tab-new" data-aos="zoom-in-right" data-aos-easing="linear" data-aos-duration="600">
                            <div className="vision-number">2</div>
                            <div className="vision-title">Sustainability</div>
                            <div className="vision-content">
                            We see a future where sustainable design is the norm. Our goal is to contribute to a greener world by integrating eco-friendly practices into our designs.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12  mb-3">
                        <div className="our-vision-tab-new" data-aos="zoom-in-right" data-aos-easing="linear" data-aos-duration="600">
                            <div className="vision-number">3</div>
                            <div className="vision-title">Global Impact</div>
                            <div className="vision-content">
                            We aspire to have a global impact, touching industries and businesses across borders. Our vision is to be a trusted partner for companies seeking excellence in mechanical design worldwide.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12  mb-3">
                        <div className="our-vision-tab-new" data-aos="zoom-in-right" data-aos-easing="linear" data-aos-duration="600">
                            <div className="vision-number">4</div>
                            <div className="vision-title">Why Our Vision Matters</div>
                            <div className="vision-content">
                            Mechanical design plays a pivotal role in shaping the products and processes that drive our daily lives. Our vision is a commitment to harness the power of design to solve complex problems and drive progress.
                            </div>
                        </div>
                    </div> <div className="col-lg-4 col-md-6 col-sm-12  mb-3">
                        <div className="our-vision-tab-new" data-aos="zoom-in-right" data-aos-easing="linear" data-aos-duration="600">
                            <div className="vision-number">5</div>
                            <div className="vision-title">Our Team's Role</div>
                            <div className="vision-content">
                            We work collaboratively, harnessing our collective expertise and passion for design to bring innovative solutions to our clients. Together, we strive to make our vision a reality, one project at a time.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12  mb-3">
                        <div className="our-vision-tab-new" data-aos="zoom-in-right" data-aos-easing="linear" data-aos-duration="600">
                            <div className="vision-number">6</div>
                            <div className="vision-title">Honesty and Integrity</div>
                            <div className="vision-content">
                                Upholding the highest professional ethical standards, treating co-workers, clients, and prospective clients with respect and kindness, and communicating openly.
                            </div>
                        </div>
                    </div>

                    <div className="clear"></div>
                </div>




            </div>


        </div>
    )
}