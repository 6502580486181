export default function OurSoftwares() {
  return (
    <div>
      <div
        className="pixie-experience"
        data-aos="flip-down"
        data-aos-easing="linear"
        data-aos-duration="600"
      >
        <div className="pixie-exp exp1">
          <div className="experience-text pb-3 ">
            CREO Mechanical, part of the PTC Creo suite, is a robust mechanical design software. It empowers engineers with advanced tools for parametric 3D modeling, simulation, and analysis, facilitating the creation of innovative and optimized mechanical designs.
          </div>
          <div className="experience-number">CREO</div>
        </div>
        <div className="pixie-exp exp2">
          <div className="experience-number pb-3">AUTODESK INVENTOR</div>
          <div className="experience-text">
            Autodesk Inventor is a leading mechanical design and 3D CAD software, known for its robust parametric modeling capabilities. It enables engineers to simulate, visualize, and test their designs, fostering innovation and efficiency in the product development process.
          </div>
        </div>
        <div className="pixie-exp exp3">
          <div className="experience-text pb-3">
            AutoCAD Mechanical is a specialized design software tailored for mechanical engineering tasks. It streamlines the creation of precise 2D and 3D mechanical designs, offering a comprehensive set of tools for drafting, documentation, and collaboration in the engineering field.
          </div>
          <div className="experience-number">AUTO CAD</div>
        </div>
        <div className="pixie-exp exp4">
          <div className="experience-number pb-3">UNIGRAPHICS Nx</div>
          <div className="experience-text">
          UNIGRAPHICS Nx is a comprehensive CAD/CAM/CAE software suite developed by Siemens PLM Software. Renowned for its versatility and advanced capabilities, UNIGRAPHICS Nx is widely utilized in industries such as aerospace, automotive, and industrial machinery.
          </div>
        </div>
      </div>
      <div className="pixie-experince1">
        <div className="pixie-exp exp1">
          <div className="experience-text pb-3">
            Autodesk Inventor is a leading mechanical design and 3D CAD software, known for its robust parametric modeling capabilities. It enables engineers to simulate, visualize, and test their designs, fostering innovation and efficiency in the product development process.
          </div>
          <div className="experience-number">AUTODESK INVENTOR</div>
        </div>
        <div className="pixie-exp exp2">
          <div className="experience-number pb-3">CREO</div>
          <div className="experience-text">
            CREO Mechanical, part of the PTC Creo suite, is a robust mechanical design software. It empowers engineers with advanced tools for parametric 3D modeling, simulation, and analysis, facilitating the creation of innovative and optimized mechanical designs.
          </div>
        </div>
        <div className="pixie-exp exp3">
          <div className="experience-text pb-3">
          UNIGRAPHICS Nx is a comprehensive CAD/CAM/CAE software suite developed by Siemens PLM Software. Renowned for its versatility and advanced capabilities, UNIGRAPHICS Nx is widely utilized in industries such as aerospace, automotive, and industrial machinery.
          </div>
          <div className="experience-number">UNIGRAPHICS Nx</div>
        </div>
        <div className="pixie-exp exp4">
          <div className="experience-number pb-3">AUTO CAD</div>
          <div className="experience-text">
            AutoCAD Mechanical is a specialized design software tailored for mechanical engineering tasks. It streamlines the creation of precise 2D and 3D mechanical designs, offering a comprehensive set of tools for drafting, documentation, and collaboration in the engineering field.
          </div>
        </div>
      </div>
    </div>
  );
}
